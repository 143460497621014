import React from "react";
import { ConfigProvider } from "antd";
import { Route, Routes } from "react-router-dom";
import Headroom from "react-headroom";
import { ToastContainer } from "react-toastify";
import { Navbar } from "components/dropdown";
import { ROUTES } from "routes";
import NotFoundComponent from "components/not-found";
// import ScrollToTop from "components/ScrollToTop";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
	// Disable right click
	document.addEventListener("contextmenu", (event) => {
		event.preventDefault();
	});
	document.querySelectorAll(".disabled").forEach((element) => {
		element.style.pointerEvents = "none";
	});

	return (
		<ConfigProvider theme={{ token: { colorPrimary: "#00b96b" } }}>
			<Headroom>
				<Navbar />
			</Headroom>
			{/* <ScrollToTop /> */}
			<a
				href="https://wa.me/+918220337175"
				target="_blank"
				rel="noreferrer"
				className="fab-whatsapp"
			>
				<img src={require("assets/images/whatsapp.png")} alt="whatsapp" />
			</a>
			<Routes>
				{ROUTES.map((route) => (
					<Route {...route} key={route.path} />
				))}
				<Route path="*" element={<NotFoundComponent />} />
			</Routes>
			<ToastContainer hideProgressBar style={{ width: "400px" }} />
		</ConfigProvider>
	);
}

export default App;
