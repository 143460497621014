import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

export function Button() {
	return (
		<Link
			to="/contact-us"
			style={{
				opacity: 1,
			}}
		>
			<button style={{
				backgroundColor: "#287A90",
				color: "#fff"
			}} className="btn">Login MMT</button>
		</Link>
	);
}
