import { toast } from 'react-toastify';
import { ToastifyNotification } from 'components/toast-component';
import { NOTIFICATION_STATUS_TYPES } from 'constants/app-constant';

export const showToast = (title = '', body = '', type = NOTIFICATION_STATUS_TYPES.SUCCESS, position = 'top-right') => {
	return toast(<ToastifyNotification type={type} title={title} body={body} />, {
		position,
		autoClose: 2000,
	});
};
