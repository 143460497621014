export const photos = [
	{
		src: "https://images.pexels.com/photos/6169021/pexels-photo-6169021.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 4,
		height: 3,
	},
	{
		src: "https://images.pexels.com/photos/6169051/pexels-photo-6169051.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 1,
		height: 1,
	},
	{
		src: "https://images.pexels.com/photos/7807594/pexels-photo-7807594.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/4489417/pexels-photo-4489417.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/6407580/pexels-photo-6407580.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 4,
		height: 3,
	},
	{
		src: "https://images.pexels.com/photos/6407579/pexels-photo-6407579.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 1,
		height: 1,
	},
	{
		src: "https://images.pexels.com/photos/27676687/pexels-photo-27676687/free-photo-of-a-truck-is-parked-on-the-side-of-the-road.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/6169591/pexels-photo-6169591.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/6169021/pexels-photo-6169021.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 4,
		height: 3,
	},
	{
		src: "https://images.pexels.com/photos/6169051/pexels-photo-6169051.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 1,
		height: 1,
	},
	{
		src: "https://images.pexels.com/photos/7807594/pexels-photo-7807594.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/4489417/pexels-photo-4489417.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/6407580/pexels-photo-6407580.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 4,
		height: 3,
	},
	{
		src: "https://images.pexels.com/photos/6407579/pexels-photo-6407579.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 1,
		height: 1,
	},
	{
		src: "https://images.pexels.com/photos/27676687/pexels-photo-27676687/free-photo-of-a-truck-is-parked-on-the-side-of-the-road.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/6169591/pexels-photo-6169591.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},

	{
		src: "https://images.pexels.com/photos/163726/belgium-antwerp-shipping-container-163726.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 4,
		height: 3,
	},
	{
		src: "https://images.pexels.com/photos/4749124/pexels-photo-4749124.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 1,
		height: 1,
	},
	{
		src: "https://images.pexels.com/photos/4320468/pexels-photo-4320468.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/7807594/pexels-photo-7807594.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	
	{
		src: "https://images.pexels.com/photos/4481258/pexels-photo-4481258.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 4,
		height: 3,
	},
	{
		src: "https://images.pexels.com/photos/27944619/pexels-photo-27944619/free-photo-of-containerterminal.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 1,
		height: 1,
	},
	{
		src: "https://images.pexels.com/photos/18083630/pexels-photo-18083630/free-photo-of-containerschiff-und-krane-im-hamburger-hafen.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},
	{
		src: "https://images.pexels.com/photos/27682296/pexels-photo-27682296/free-photo-of-photo.jpeg?auto=compress&cs=tinysrgb&w=800",
		width: 3,
		height: 4,
	},

	// add more transport logicstick images here
	
];
