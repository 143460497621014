import React from "react";
import { Row, Col } from "antd";
import Footer from "components/footer";
import BannerSection from "components/top-section";
import LeadingCompanies from "./leading-companies";
// import OurClients from './our-clients';
// import WhyChoose from './why-choose';
// import FeaturedResources from './featured-resource';
// import ToSupportUs from './to-support-us';
// import OurServices from './our-services';
import "./home.scss";
import OurServicesNew from "./our-services-new";
import OurServicesNew1 from "./our-services-new1";

// Technology
// At MMT Supply chain Systems , we leverage cutting-edge technology to ensure
// efficiency, transparency, and reliability:
// Real-time Tracking: Monitor your shipments 24/7
// Advanced Analytics: Optimize routes and reduce costs
// Integration Capabilities: Seamlessly connect with your existing systems

//  Sustainability
// We're committed to sustainable practices in freight transportation:
// Eco-friendly Fleet: Investing in low-emission vehicles
// Optimal Route Planning: Reducing fuel consumption and emissions
// Intermodal Solutions: Promoting greener transportation options
// Paperless Operations: Minimizing our environmental impact

const data = [
	{
		title: "Technology",
		desc: "At MMT Supply chain Systems , we leverage cutting-edge technology to ensure efficiency, transparency, and reliability:",
		data: [
			{
				title: "Real-time Tracking",
				desc: "Monitor your shipments 24/7",
			},
			{
				title: "Advanced Analytics",
				desc: "Optimize routes and reduce costs",
			},
			{
				title: "Integration Capabilities",
				desc: "Seamlessly connect with your existing systems",
			},
		],
	},
	{
		title: "Sustainability",
		desc: "We're committed to sustainable practices in freight transportation:",
		data: [
			{
				title: "Eco-friendly Fleet",
				desc: "Investing in low-emission vehicles",
			},
			{
				title: "Optimal Route Planning",
				desc: "Reducing fuel consumption and emissions",
			},
			{
				title: "Intermodal Solutions",
				desc: "Promoting greener transportation options",
			},
			{
				title: "Paperless Operations",
				desc: "Minimizing our environmental impact",
			},
		],
	},
];

export default function Home() {
	return (
		<Row justify={"center"}>
			<BannerSection
				isHome
				title="From Ideas to Implementation"
				bannerText="Blending creativity and technology to boost business growth"
				animatedText
			/>
			<Col span={20}>
				<LeadingCompanies />
			</Col>
			<Col span={20}>
				<OurServicesNew />
			</Col>
			{/* <Col span={20}>
				<OurServicesNew
					title="Our Services"
					desc="MMT Supply chain Systems offers a comprehensive range of freight transportation services"
				/>
			</Col> */}
			{data?.map((item, index) => (
				<Col span={20} key={index}>
					<OurServicesNew1
						title={item.title}
						desc={item.desc}
						data={item.data}
					/>
				</Col>
			))}
			{/* <Col xl={20} sm={24}>
				<OurServices />
			</Col>
			<Col xl={20} sm={24}>
				<WhyChoose />
			</Col>
			<OurClients />
			<Col xl={20} sm={24}>
				<ToSupportUs />
			</Col>
			<Col xl={20} sm={24}>
				<FeaturedResources />
			</Col> */}
			<Footer />
		</Row>
	);
}
