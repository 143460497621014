import React from "react";
import { Col, Row } from "antd";
import Footer from "components/footer";
import BannerSection from "components/top-section";
import CoreValues from "./core-values";
import EngagementModel from "./engagement-model";
// import OurMission from "./out-mission";
// import OurVision from "./out-vision";
// import OurBackground from "./out-background";
import "./about.scss";

const ServicePage = () => {
	return (
		<Row justify={"center"}>
			<Col span={24}>
				<BannerSection
					isAbout
					title="About us"
					desc="Welcome to MMT Supply chain Systems , your trusted partner in comprehensive freight transportation services. As a subsidiary of TMT-Thiruchandur Murugan Thunai , a leader in 3PL solutions for multinational corporations, we bring more than a  decades of expertise to meet your freight needs with precision and reliability."
				/>
			</Col>
			<Col span={20}>
				{/* <OurMission />
				<OurVision />
				<OurBackground /> */}
				<CoreValues />
				<EngagementModel />
				{/* <OurApproach /> */}
			</Col>
			<Col span={24}>
				<Footer />
			</Col>
		</Row>
	);
};

export default ServicePage;
