import React from 'react';
import CollapsibleComponent from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';

const Collapsible = ({ title = '', description = '', list = [] }) => (
	<CollapsibleComponent trigger={[title, <BsChevronDown />]} accordionPosition>
		<p
			style={{
				color: 'rgba(129, 129, 172, 1)',
			}}>
			{description}
		</p>
		<h2
			style={{
				fontSize: '18px',
				marginTop: 20,
			}}
			className="title">
			Our use cases
		</h2>
		<ul className="use_cases">
			{list?.map((useCase, index) => (
				<li key={index}>{useCase}</li>
			))}
		</ul>
	</CollapsibleComponent>
);

export default Collapsible;
