import React from "react";
import { Col, Row } from "antd";
import FixedBid from "../../assets/svgs/engagement-model/fixedbid.svg";
import Retainer from "../../assets/svgs/engagement-model/retainer.svg";
import Time from "../../assets/svgs/engagement-model/time.svg";

const MODELS = [
	{
		name: "3PL Experts Model",
		logo: FixedBid,
		desc: "Over 20 years of experience in 3PL services.",
	},
	{
		name: "Industry Partner Model",
		logo: Time,
		desc: "Trusted partner to various Industries.",
	},
	{
		name: "Supply Chain Optimizer Model",
		logo: Retainer,
		desc: "Proven track record of optimizing complex supply chains.",
	},
];

const EngagementModel = () => {
	return (
		<Row className="engagement_model">
			<Col sm={24} xl={24}>
				<Row justify={"center"} align={"middle"}>
					<Col sm={24} xl={16}>
						<h1 className="title">
							Our <span>Engagement Model</span>
						</h1>
						<p className="desc">
							Our core values are the pillars that define our identity and guide
							our actions. These values underpin MMT commitment to excellence,
							client success, and industry leadership.
						</p>
					</Col>
					<Col span={24} className="feature_container">
						{MODELS?.map((model, index) => (
							<div className="container" key={index}>
								<div className="image_container">
									<img src={model.logo} alt={model.name} />
									<h3 className="name">{model.name}</h3>
									<span className="desc">{model.desc}</span>
								</div>
							</div>
						))}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default EngagementModel;
