import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import ReadMore from 'assets/svgs/read-more.svg';
import { PAGE_DETAILS } from 'constants/data';
import Footer from 'components/footer';
import './blogs.scss';

const Blogs = () => {
	let navigate = useNavigate();
	return (
		<Row justify={'center'} className="blogs">
			<Col sm={24} xl={20}>
				<Row
					justify={'center'}
					align={'middle'}
					gutter={[20, 20]}
					style={{
						margin: 0,
					}}>
					<Col sm={24} xl={16}>
						<h1 className="title">Explore our well crafted blogs</h1>
						<p className="desc">
							Discover thought-provoking content featuring comprehensive insights, expert viewpoints, and practical wisdom. Dive into
							our blogs to stay ahead in the ever-changing tech realm
						</p>
					</Col>
					<Col span={24} className="feature_container">
						<Col sm={24} xl={24} className="para_title">
							Latest Blog
						</Col>
						<Row
							style={{
								margin: 0,
							}}>
							{PAGE_DETAILS.BLOGS.filter((blog) => blog.latest).map((blog, index) => (
								<Col sm={24} xl={24} className="latest_container" key={index} onClick={() => navigate(`/blogs/${blog?.id}`)}>
									<Row className="image_name_container">
										<Col xl={8} className="image_container">
											<img src={require('../../assets/images/blogs/latest.png')} alt={'latest'} />
										</Col>
										<Col xl={14} className="text_area">
											<div className="text_area_container">
												<span>{blog?.topic}</span>
												<span className="blog_title">{blog?.title}</span>
												<Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="content">
													{blog?.description}
												</Typography.Paragraph>
												<span className="date">{blog.date}</span>
											</div>
										</Col>
									</Row>
								</Col>
							))}
						</Row>
					</Col>
					<Col span={24} className="feature_container">
						<Col sm={24} xl={24} className="para_title">
							Featured blogs
						</Col>
						<Row
							gutter={[16, 0]}
							className="scroll_on_mobile"
							style={{
								margin: 0,
							}}>
							{PAGE_DETAILS.BLOGS.filter((blog) => !blog.latest)?.map((blog, index) => (
								<Col
									sm={24}
									xl={6}
									className="container"
									key={index}
									onClick={() => navigate(`/blogs/${blog?.id}`)}
									style={{
										cursor: 'pointer',
									}}>
									<div className="image_name_container">
										<div className="image_container">
											<img src={blog.image} alt={blog.name} />
										</div>
										<div className="textArea">
											<div className="top">
												<span className="tech">{blog.topic}</span>
												<span className="time">{blog.date}</span>
											</div>
											<Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="name">
												{blog.title}
											</Typography.Paragraph>
											<div className="read_more_container">
												<span className="read_more">Read More </span>
												<img src={ReadMore} alt="read more" />
											</div>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Col>
					{/* <Col>
						<Button
							style={{
								borderRadius: '100px',
								minHeight: '50px',
								padding: '0 20px',
							}}>
							View all articles
						</Button>
					</Col> */}
				</Row>
			</Col>
			<Col span={24}>
				<Footer />
			</Col>
		</Row>
	);
};

export default Blogs;
