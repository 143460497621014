import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
// import BannerSection from 'components/top-section';
import ReadMore from 'assets/svgs/read-more.svg';
import { PAGE_DETAILS } from 'constants/data';
import Footer from 'components/footer';
import './blogs.scss';

const BlogsDetails = () => {
	const { blogID } = useParams();
	let navigate = useNavigate();
	const details = PAGE_DETAILS.BLOGS.find((blog) => blog.id === blogID) || null;

	if (!details) {
		return <h1>No Data Found</h1>;
	}

	return (
		<Row justify={'center'} className="blog_details">
			<Col sm={24} xl={18} className="blog_container">
				<div className="topic">
					<h1>{details.topic}</h1>
					<p className="desc">{details?.description}</p>
					<img src={details?.image} alt="blog" />
					<p className="desc">{details?.subDescription}</p>
					<div className="author">Author : {details?.author}</div>
				</div>
			</Col>
			<Col sm={24} xl={20} className="feature_container">
				<Col sm={24} xl={24} className="para_title">
					<h1 className="other_blogs">
						Other <span>blogs</span>
					</h1>
				</Col>
				<Row
					gutter={[16, 0]}
					className="scroll_on_mobile"
					style={{
						margin: 0,
					}}>
					{PAGE_DETAILS.BLOGS.filter((blog) => !blog.latest && blog.id !== blogID)?.map((blog, index) => (
						<Col sm={24} xl={6} className="container" key={index} onClick={() => navigate(`/blogs/${blog?.id}`)}>
							<div className="image_name_container">
								<div className="image_container">
									<img src={blog.image} alt={blog.name} />
								</div>
								<div className="textArea">
									<div className="top">
										<span className="tech">{blog.topic}</span>
										<span className="time">{blog.date}</span>
									</div>
									<Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className="name">
										{blog.title}
									</Typography.Paragraph>
									<div className="read_more_container">
										<span className="read_more">Read More </span>
										<img src={ReadMore} alt="read more" />
									</div>
								</div>
							</div>
						</Col>
					))}
				</Row>
			</Col>
			<Col span={24}>
				<Footer />
			</Col>
		</Row>
	);
};

export default BlogsDetails;
