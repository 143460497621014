import React from "react";
import { Col, Row } from "antd";
// import { useNavigate } from "react-router-dom";
// import TypewriterComponent from "typewriter-effect";
// import Button from "components/button";
import "./styles.scss";

const BannerSection = ({
	title = "",
	desc = "",
	bannerText = "",
	isAbout,
	animatedText,
	headText = "Who are we?",
	isHome = false,
}) => {
	// let navigate = useNavigate();
	return (
		<Col span={24} className="top_section">
			<Row>
				<Col sm={24} xl={24} className="banner_wrap">
					<Row className="container">
						<Col sm={24} xl={12} className="left_container">
							<div className="content">
								<h1 className="heading_title">
									Get your shipment picked up <br />
									from your
									<span>
										<strong
											style={{
												color: "rgb(255, 49, 49)",
												marginLeft: 10
											}}
										>
											doorstep
										</strong>
									</span>
								</h1>
								<div className="download_container">
									<img
										src={require("../../assets/images/google.png")}
										alt="google"
									/>
									<img
										src={require("../../assets/images/apple.png")}
										alt="apple"
									/>
								</div>
							</div>
						</Col>
						<Col sm={24} xl={12} className="right_container">
							<Row
								style={{
									width: "100%",
								}}
								justify={"center"}
							>
								<Col
									sm={24}
									xl={12}
									style={{
										width: "100%",
									}}
								>
									<div className="input_container">
										<div className="title_row">
											<img
												src={require("../../assets/images/delivery-truck.png")}
												alt="delivery-truck"
											/>
											<h3 className="title">Track your shipment</h3>
										</div>
										<input
											type="text"
											placeholder="Enter your tracking number"
											className="input"
										/>
										<button
											data-v-859c5894=""
											class="otp-button mt-6 rounded-[4px] py-3.8 px-5 text-[14px] w-full block text-center dark-bg text-white font-semibold
                        is-disabled cursor-not-allowed"
										>
											Track
										</button>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default BannerSection;
