import React from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Button from "components/button";

const LeadingCompanies = () => {
	let navigate = useNavigate();
	return (
		<Col span={24}>
			<Row className="leading_companies">
				<Col sm={24} xl={8} className="left_area">
					<img
						className="image"
						src={require("../../assets/images/leading.png")}
						alt="leading companies"
					/>
				</Col>
				<Col sm={24} xl={2} />
				<Col sm={24} xl={14} className="right_area">
					<Row>
						<Col sm={24} xl={14}>
							<div className="text_area">
								<div className="title">
									<h1>
										MMT Supply chain Systems
										{/* <span>for IT solutions</span> */}
									</h1>
								</div>
								<p className="desc">
									Welcome to MMT Supply chain Systems , your trusted partner in
									comprehensive freight transportation services. As a subsidiary
									of TMT-Thiruchandur Murugan Thunai , a leader in 3PL solutions
									for multinational corporations, we bring more than a decades
									of expertise to meet your freight needs with precision and
									reliability.
								</p>
								<Button onClick={() => navigate("/about")}>Know More</Button>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default LeadingCompanies;
