import React from "react";
import { Row, Col } from "antd";
// import {
// 	Link,
// 	// useNavigate
// } from "react-router-dom";
// import Button from "components/button";
// import Triangles from "../../assets/svgs/Triangles.svg";
// import DesktopFooter from "../../assets/svgs/DesktopFooter.svg";
import "./footer.scss";

const Footer = () => {
	// const navigate = useNavigate();
	return (
		<Col span={24} className="footer">
			<Row justify={"center"}>
				<Col sm={24} xl={24}>
					<Row
						style={{
							padding: 40,
						}}
					>
						<Col xl={16} sm={24} className="left_area">
							{/* <h1>MMT Supply Chain Systems</h1> */}
							<h4>
								Ready to optimize your freight transportation? Get in touch with
								MMT Supply chain Systems today.
							</h4>
							<Row>
								<Col xl={8}>
									<a
										href="mailto:admin@mmttransport.in"
										class="info-email"
										target="_blank"
										rel="noreferrer"
										style={{
											color: "#fff",
											marginTop: 20,
											display: "block",
										}}
									>
										<h4>
											BO : Survey # 286/3, Rayakottah, Main Road, Onnalvadi,
											Hosur Taluk HOSUR • 635109.
										</h4>
									</a>
									<a
										href="mailto:admin@mmttransport.in"
										class="info-email"
										target="_blank"
										rel="noreferrer"
										style={{
											color: "#fff",
											display: "block",
											marginTop: 10,
										}}
									>
										<h4>
											HO : #43, Adithya Complex, Vallalar Nagar, Vilankurichi
											Road, Vilankurichi, COIMBATORE - 614035
										</h4>
									</a>
									<a
										href="https://wa.me/+918220337175"
										class="info-email"
										target="_blank"
										rel="noreferrer"
										style={{
											color: "#fff",
											display: "block",
											marginTop: 10,
										}}
									>
										<h4>
										+918220337175
										</h4>
									</a>
									{/* <a
										href="mailto:admin@mmttransport.in"
										class="info-email"
										target="_blank"
										rel="noreferrer"
										style={{
											color: "#fff",
											display: "block",
											marginTop: 10,
										}}
									>
										<h4>
											HO : #43, Adithya Complex, Vallalar Nagar, Vilankurichi
											Road, Vilankurichi, COIMBATORE - 614035
										</h4>
									</a> */}
								</Col>
							</Row>
						</Col>
						<Col span={8} className="right_area">
							<div className="visit-card">
								<div className="name">Naveen Prasad.J</div>
								<div className="role">Zonal Manager</div>
								<div className="mobile">Mobile: 8220337175</div>
								<div className="email">Email: naveen@mmttransport.in</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={24} className="bottom_area">
							<Row>
								<Col xl={24} sm={24} className="left_area" style={{
									    padding: "20px",
										textAlign: "right"
								}}>
									<div className="copy">
									Copyright © 2024 – MMT Supply Chain Systems
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};

export default Footer;
