import React from "react";
import { Col, Row } from "antd";

const DATA = [
	{
		title: "Expertise",
		desc: "Leverage our deep industry knowledge and best practices",
	},
	{
		title: "Wide Network",
		desc: "Access our extensive transportation network across PAN India",
	},
	{
		title: "Technology-Driven",
		desc: "Benefit from our state-of-the-art tracking and management systems",
	},
	{
		title: "Customer-Centric",
		desc: "Experience our commitment to tailored solutions and exceptional service",
	},
	{
		title: "Sustainability",
		desc: "Partner with a company dedicated to reducing carbon footprint in freight operations ( under progress)",
	},
];

const OurServicesNew = ({
	data = DATA,
	title = "Why Choose MMT Supply chain Systems ?",
	desc = "Embracing flexible engagement models, we tailor our services to match your unique needs and aspirations",
}) => {
	return (
		<Row className="our_services_new">
			<Col sm={24} xl={24}>
				<h1
					style={{
						color: "#1B5E70",
					}}
					className=""
				>
					{title}
				</h1>
				<h3 className="our_services_new_desc">{desc}</h3>
				<Row
					gutter={[10, 0]}
					style={{
						margin: 0,
					}}
					className="our_services_new_container"
				>
					{data?.map((data, index) => (
						<Col
							xl={8}
							sm={24}
							key={index}
							className="our_services_new_content"
						>
							<div className="our_services_new_content_inner">
								<Row>
									<Col span={4}>
										<div
											className="circle"
											style={{
												backgroundColor: "#f4f4f4",
												width: 40,
												height: 40,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												borderRadius: "100%",
												color: "#000",
											}}
										>
											{index + 1}
										</div>
									</Col>
									<Col span={20}>
										<div>
											<h3>{data?.title}</h3>
											<p>{data?.desc}</p>
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					))}
				</Row>
			</Col>
		</Row>
	);
};

export default OurServicesNew;
