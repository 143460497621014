import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

const Button = ({ children, style, className, onClick }) => {
	return (
		<button className={`button_style ${className}`} style={style} onClick={onClick}>
			{children}
		</button>
	);
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	style: PropTypes.object,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default Button;
