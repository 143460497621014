import React from "react";
import { Col, Row } from "antd";
import Image from "../../assets/svgs/our-mission/our-mission.svg";

const OurBackground = () => {
	return (
		<Col span={24}>
			<Row className="our_mission">
				<Col sm={24} xl={14} className="right_area">
					<Row>
						<Col sm={24} xl={24}>
							<div className="text_area">
								<div className="title">
									<h1>
										Our <span>Background</span>
									</h1>
								</div>
								<span className="subtitle">
									Our journey to becoming one of the leading companies in the
									3PL
								</span>
								<p className="desc">
									For years, TMT has been at the forefront of providing
									top-notch 3PL services to MNCs and their diverse vendor
									networks. Our reputation for reliability, efficiency, and
									customer satisfaction has paved the way for our expansion into
									the freight transportation sector. With MMT Supply chain
									Systems we aim to bring the same level of excellence to the
									broader transportation industry.
								</p>
							</div>
						</Col>
					</Row>
				</Col>
				<Col sm={24} xl={2} />
				<Col sm={24} xl={8} className="left_area">
					<img className="image" src={Image} alt="leading companies" />
				</Col>
			</Row>
		</Col>
	);
};

export default OurBackground;
