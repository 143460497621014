import React, { 
	// useState, useCallback
 } from 'react';
import GalleryComponent from 'react-photo-gallery';
// import Carousel, { Modal, ModalGateway } from 'react-images';
import { Col, Row } from 'antd';
import { photos } from './photos';
import './styles.scss'

function Gallery() {
	// const [currentImage, setCurrentImage] = useState(0);
	// const [viewerIsOpen, setViewerIsOpen] = useState(false);

	// const openLightbox = useCallback((event, { photo, index }) => {
	// 	setCurrentImage(index);
	// 	setViewerIsOpen(true);
	// }, []);

	// const closeLightbox = () => {
	// 	setCurrentImage(0);
	// 	setViewerIsOpen(false);
	// };

	return (
		<Row>
			<Col span={24}>
				<GalleryComponent photos={photos} 
				// onClick={openLightbox}
				 />
				{/* <ModalGateway>
				{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
							currentIndex={currentImage}
							views={photos.map((x) => ({
								...x,
								srcset: x.srcSet,
								caption: x.title,
							}))}
						/>
					</Modal>
				) : null}
			</ModalGateway> */}
			</Col>
		</Row>
	);
}

export default Gallery;
