import Home from 'pages/home';
import SignUp from 'pages/SignUp';
import Marketing from 'pages/Marketing';
import Consulting from 'pages/Consulting';
import About from 'pages/about';
import ContactUs from 'pages/contact-us';
import JoinOurTeam from 'pages/join-our-team';
import Services from 'pages/services';
import Blogs from 'pages/blogs';
import BlogsDetails from 'pages/blogs/blog-details';
import Technology from 'pages/technology';
import Admin from 'pages/admin';
import Gallery from 'pages/gallery';
import ServicePage from 'pages/service-page';

export const ROUTES = [
	{
		path: '/services/:serviceName',
		element: <Services />,
	},
	{
		path: '/blogs/:blogID',
		element: <BlogsDetails />,
	},
	{
		path: '/blogs',
		element: <Blogs />,
	},
	{
		path: '/technology',
		element: <Technology />,
	},
	{
		path: '/contact-us',
		element: <ContactUs />,
	},
	{
		path: '/admin',
		element: <Admin />,
	},
	{
		path: '/gallery',
		element: <Gallery />,
	},
	{
		path: '/services',
		element: <ServicePage />,
	},
	{
		path: '/join-with-us',
		element: <JoinOurTeam />,
	},
	{
		path: '/sign-up',
		element: <SignUp />,
	},
	{
		path: '/marketing',
		element: <Marketing />,
	},
	{
		path: '/consulting',
		element: <Consulting />,
	},
	{
		path: '/about',
		element: <About />,
	},
	{
		path: '/',
		element: <Home />,
	},
];
